import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useContext,
} from "react"
import OverallReviews from "./OverallReviews"
import ReviewItem from "./ReviewItem"
import loadable from "@loadable/component"
import { AppContext } from "../context/context"

const VirtualizedList = loadable(() => import("@dwqs/react-virtual-list"))

const ReviewList = () => {
  const [filterStatus, setFilterStatus] = useState(["DateDesc", false, false])
  const [reviews, setReviews] = useState([])
  const [reviewsSorted, setReviewsSorted] = useState([])
  const [reviewsFilteredByCity, setReviewFiltered] = useState([])
  const [reviewsFilteredByRating, setReviewFilteredByRating] = useState([])
  const [activeReviews, setActiveReviews] = useState([])
  const [cities, setCities] = useState([])
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useContext(AppContext)

  /* eslint-disable */
  const getPestroutesClientReviews = async () => {
    if (typeof PestroutesClient !== "object") {
      return setTimeout(getPestroutesClientReviews, 100)
    }
    const result = await PestroutesClient.getReviews({
      favorable: 1,
    })

    if (result.success) {
      setReviews(result.reviews)
      setReviewsSorted(result.reviews)
      setReviewFiltered(result.reviews)
      setReviewFilteredByRating(result.reviews)
      setActiveReviews(result.reviews)
      let cty = []
      result.reviews.forEach(review => {
        cty.push(review.city)
      })

      cty = Object.values(
        Object.fromEntries(cty.map(s => [s.toLowerCase(), s]))
      )
      setCities(cty)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 200)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])

  const handleSort = e => {
    if (e.target.name === "sortOrder" && e.target.value === "dateDescending")
      sortDateDesc()
    if (e.target.name === "sortOrder" && e.target.value === "dateAscending")
      sortDateAsc()
    if (e.target.name === "sortOrder" && e.target.value === "ratingAscending")
      sortRatingAsc()
    if (e.target.name === "sortOrder" && e.target.value === "ratingDescending")
      sortRatingDesc()

    if (e.target.name === "city") filterCities(e.target.value)
    if (e.target.name === "star") filterRatings(e.target.value)
  }

  const sortDateDesc = () => {
    let temp = reviewsSorted.sort((a, b) => (a.date < b.date ? 1 : -1))
    setReviewsSorted([...temp])

    let temp2 = reviewsFilteredByCity.sort((a, b) => (a.date < b.date ? 1 : -1))
    setReviewFiltered([...temp2])

    let temp3 = reviewsFilteredByRating.sort((a, b) =>
      a.date < b.date ? 1 : -1
    )
    setReviewFilteredByRating([...temp3])

    filterStatus[0] = "DateDesc"
    setFilterStatus(filterStatus)
  }
  const sortDateAsc = () => {
    let temp = reviewsSorted.sort((a, b) => (a.date > b.date ? 1 : -1))
    setReviewsSorted([...temp])

    let temp2 = reviewsFilteredByCity.sort((a, b) => (a.date > b.date ? 1 : -1))
    setReviewFiltered([...temp2])

    let temp3 = reviewsFilteredByRating.sort((a, b) =>
      a.date > b.date ? 1 : -1
    )
    setReviewFilteredByRating([...temp3])

    filterStatus[0] = "DateAsc"
    setFilterStatus(filterStatus)
  }
  const sortRatingDesc = () => {
    let temp = reviewsSorted.sort((a, b) => (a.rating < b.rating ? 1 : -1))
    setReviewsSorted([...temp])

    let temp2 = reviewsFilteredByCity.sort((a, b) =>
      a.rating < b.rating ? 1 : -1
    )
    setReviewFiltered([...temp2])

    let temp3 = reviewsFilteredByRating.sort((a, b) =>
      a.rating < b.rating ? 1 : -1
    )
    setReviewFilteredByRating([...temp3])

    filterStatus[0] = "RatingDesc"
    setFilterStatus(filterStatus)
  }
  const sortRatingAsc = () => {
    let temp = reviewsSorted.sort((a, b) => (a.rating > b.rating ? 1 : -1))

    let temp2 = reviewsFilteredByCity.sort((a, b) =>
      a.rating > b.rating ? 1 : -1
    )
    setReviewFiltered([...temp2])

    let temp3 = reviewsFilteredByRating.sort((a, b) =>
      a.rating > b.rating ? 1 : -1
    )
    setReviewFilteredByRating([...temp3])

    setReviewsSorted([...temp])
    filterStatus[0] = "RatingAsc"
    setFilterStatus(filterStatus)
  }
  const filterCities = city => {
    let citySelector = document.querySelector("select[name='city']")
    let ratingSelector = document.querySelector("select[name='star']")
    if (city === "All Cities") {
      setReviewFiltered([...reviews])
      filterStatus[1] = false
      filterStatus[2] = false

      citySelector.selectedIndex = 0
      ratingSelector.selectedIndex = 0
      setFilterStatus(filterStatus)
    } else {
      let temp = reviewsSorted.filter(review => {
        return review.city === city
      })
      setReviewFiltered([...temp])
      setReviewFilteredByRating([...temp])
      filterStatus[1] = true
      filterStatus[2] = false
      setFilterStatus(filterStatus)
      ratingSelector.selectedIndex = 0
    }
  }
  const filterRatings = stars => {
    if (stars === "All Ratings") {
      setReviewsSorted([...reviews])
      filterStatus[1] = false
      filterStatus[2] = false
      var citySelector = document.querySelector("select[name='city']")
      var ratingSelector = document.querySelector("select[name='star']")
      citySelector.selectedIndex = 0
      ratingSelector.selectedIndex = 0
    } else {
      let temp = reviewsFilteredByCity.filter(review => {
        return review.rating === stars
      })
      setReviewFilteredByRating([...temp])
      filterStatus[2] = true
    }
    setFilterStatus(filterStatus)
  }

  const updateActiveReviews = useCallback(() => {
    let rvs = []
    if (!filterStatus[1] && !filterStatus[2]) {
      rvs = reviewsSorted
    } else if (filterStatus[1] && !filterStatus[2]) {
      rvs = reviewsFilteredByCity
    } else {
      rvs = reviewsFilteredByRating
    }
    setActiveReviews(rvs)
  }, [
    filterStatus,
    reviewsSorted,
    reviewsFilteredByRating,
    reviewsFilteredByCity,
  ])

  useEffect(() => {
    updateActiveReviews()
    forceUpdate()
  }, [
    activeReviews,
    reviews,
    reviewsFilteredByCity,
    reviewsFilteredByRating,
    filterStatus,
    updateActiveReviews,
  ])

  const renderItems = ({ index, isScrolling }) => {
    if (activeReviews.length) {
      return <ReviewItem key={index} review={activeReviews[index]}></ReviewItem>
    } else {
      return null
    }
  }
  let updateVirtualList = (
    <VirtualizedList
      itemCount={activeReviews.length}
      estimatedItemHeight={120}
      renderItem={renderItems}
    />
  )

  return (
    <>
      <div className="grayBkg">
        <div className="container reviewsOptionContainer">
          <OverallReviews></OverallReviews>
          <div className="reviewFiltersContainer">
            Filter Results
            <select
              name="sortOrder"
              className="sortOrderDropDown"
              onChange={handleSort}
              onBlur={handleSort}
            >
              <option defaultValue value="dateDescending">
                Date Descending
              </option>
              <option value="dateAscending">Date Ascending</option>
              <option value="ratingDescending">Rating Descending</option>
              <option value="ratingAscending">Rating Ascending</option>
            </select>
            <select
              name="city"
              className="citiesDropDown"
              onChange={handleSort}
              onBlur={handleSort}
            >
              <option defaultValue value="All Cities">
                All Cities
              </option>
              {cities.map(city => {
                return (
                  <option key={city} values={city}>
                    {city}
                  </option>
                )
              })}
            </select>
            <select
              name="star"
              className="ratingsDropDown"
              onChange={handleSort}
              onBlur={handleSort}
            >
              <option defaultValue value="All Ratings">
                All Ratings
              </option>
              <option value="5">5 Star</option>
              <option value="4">4 Star</option>
              <option value="3">3 Star</option>
              <option value="2">2 Star</option>
              <option value="1">1 Star</option>
            </select>
          </div>
        </div>
      </div>
      <div className="container">{updateVirtualList}</div>
    </>
  )
}

export default ReviewList
