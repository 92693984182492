import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import SimpleHero from "../components/simple-hero/SimpleHero"
import SingleReview from "../components/reviews/SingleReview"
import ReviewList from "../components/reviews/ReviewList"
import { graphql, useStaticQuery } from "gatsby"
import GoogleRating from "../components/google-rating-banner/GoogleRating"

const ReviewsPage = ({ pageContext }) => {
  const data = useStaticQuery(getReviewsQuery)
  const { banner, seo_metadata__meta_title, seo_metadata__meta_description } =
    data.allKontentItemPage.nodes[0].elements

  const { review_images, google_rating_banner } =
    data.kontentItemSettings.elements

  let google_url
  if (google_rating_banner.value[0])
    google_url = google_rating_banner.value[0].elements.google_url

  const { slug } = pageContext

  return (
    <Layout location={slug}>
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
      ></SEO>
      <SimpleHero banner={banner.value[0]} />
      {/*google_url && google_url.value && <GoogleRating url={google_url} />*/}
      <div className="container">
        <SingleReview reviewImages={review_images} />
      </div>
      <ReviewList></ReviewList>
    </Layout>
  )
}

const getReviewsQuery = graphql`
  query pestroutesReviews {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "reviews" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ... on kontent_item_simple_hero_banner {
                id
                elements {
                  heading {
                    value
                  }
                  subheading {
                    value
                  }
                  background_image {
                    value {
                      description
                      fluid(maxWidth: 5000, quality: 75) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  background_color {
                    value {
                      name
                    }
                  }
                }
              }
            }
          }
          content_blocks {
            value {
              ... on kontent_item_content_block {
                id
                __typename
                elements {
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  buttons {
                    value {
                      ... on kontent_item_anchor_button {
                        id
                        elements {
                          anchor_reference {
                            value {
                              name
                            }
                          }
                          button_text {
                            value
                          }
                        }
                      }
                      ... on kontent_item_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          linked_page {
                            value {
                              ... on kontent_item_page {
                                id
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  image_gallery {
                    value {
                      ... on kontent_item_image_gallery {
                        id
                        elements {
                          gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  html {
                    value
                  }
                }
              }
              ... on kontent_item_google_rating_banner {
                id
                __typename
                elements {
                  google_url {
                    value
                  }
                }
              }
            }
          }
          cta_form_stub {
            value {
              ... on kontent_item_form_stub_banner {
                id
                elements {
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  heading {
                    value
                  }
                  button_text {
                    value
                  }
                  subheading {
                    value
                  }
                  fields {
                    value {
                      ... on kontent_item_form_field {
                        id
                        elements {
                          form_field {
                            value {
                              codename
                            }
                          }
                          field_type {
                            value {
                              codename
                            }
                          }
                          label {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          linked_item {
            value {
              ... on kontent_item_pest {
                id
                elements {
                  name {
                    value
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 60) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  short_description {
                    value
                  }
                  slug {
                    value
                  }
                }
              }
              ... on kontent_item_service_area {
                id
                elements {
                  label {
                    value
                  }
                  slug {
                    value
                  }
                  service_area_name {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
    kontentItemSettings {
      elements {
        google_rating_banner {
          value {
            ... on kontent_item_google_rating_banner {
              id
              elements {
                google_url {
                  value
                }
              }
            }
          }
        }
        pestroutes_reviews {
          value {
            codename
          }
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                tracking_number {
                  value
                }
                phone_number {
                  value
                }
                name {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                address_line_1 {
                  value
                }
                address_line_2 {
                  value
                }
              }
            }
          }
        }
        reviews {
          value {
            ... on kontent_item_review {
              id
              elements {
                heading {
                  value
                }
                location {
                  value {
                    ... on kontent_item_service_area {
                      id
                      elements {
                        label {
                          value
                        }
                        service_area_name {
                          value
                        }
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                date {
                  value(formatString: "DD MMMM YYYY")
                }
                rating {
                  value {
                    codename
                  }
                }
                review {
                  value
                }
                reviewer {
                  value
                }
                review_source_link {
                  value
                }
              }
            }
          }
        }
        review_images {
          value {
            ... on kontent_item_review_image {
              id
              elements {
                review_image {
                  value {
                    fluid(maxWidth: 768) {
                      ...KontentAssetFluid_withWebp_noBase64
                    }
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ReviewsPage
